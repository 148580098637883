/* eslint-disable @typescript-eslint/naming-convention */
import sumBy from 'lodash/sumBy';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import { Objkt } from '~/types';

export const getUserHoldings = (objkt: Objkt, address: string) => {
  const {
    token_holders = [],
    validSwaps = [],
  } = objkt || {};
  const { quantity = 0 } = token_holders.find(({ holder }) => holder?.address === address) || {};
  const listings = sumBy(validSwaps.filter(({ creator }) => creator.address === address), 'amount_left') || 0;
  return {
    quantity,
    listings,
    total: quantity + listings,
  };
};

const useCurrentUserHoldings = (objkt: Objkt) => {
  const { acc } = useHicetnuncContext();
  return getUserHoldings(objkt, acc?.address);
};

export default useCurrentUserHoldings;
