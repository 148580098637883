import { TransactionWalletOperation } from '@taquito/taquito';
import { BatchWalletOperation } from '@taquito/taquito/dist/types/wallet/batch-operation';
import React from 'react';

const useBatchOperation = () => {
  const [txStatus, setTxStatus] = React.useState<'idle' | 'pending' | 'mining' | 'success' | 'error'>('idle');
  const [txHash, setTxHash] = React.useState<string>(null);
  const [txError, setTxError] = React.useState<string>(null);
  const txRun = async (executeOperation: () => Promise<BatchWalletOperation | TransactionWalletOperation>) => {
    try {
      setTxError(null);
      setTxHash(null);
      setTxStatus('pending');
      const batchOp = await executeOperation();
      setTxStatus('mining');
      setTxHash(batchOp.opHash);
      await batchOp.confirmation();
      setTxStatus('success');
    } catch (err) {
      console.log('Error running tx.', err);
      setTxError(err.message);
      setTxStatus('idle');
    }
  };
  const txCancel = () => {
    setTxStatus('idle');
    setTxHash(null);
    setTxError(null);
  };
  return { txStatus, txError, txHash, txRun, txCancel };
};

export default useBatchOperation;
