import styled from 'styled-components';
import { Table as SuperTable, Th } from 'react-super-responsive-table';
import { media } from '~/styles/media';
import { headerHeight } from './Layout';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

export const tableHeaderHeight = 31;

const Table = styled(SuperTable).attrs({
  border: 1,
})`
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  border: ${({ theme }) => theme.colors.borderXLight} solid thin;
  width: 100%;
  max-width: 100%;
  th, td {
    padding: 5px 15px;
    overflow: hidden;
    text-overflow: ellipsis; 
    word-wrap: break-word;
    white-space: nowrap;
  }
  th {
    height: ${tableHeaderHeight}px;
    text-align: left;
    position: sticky;
    top: ${headerHeight}px;
    background-color: ${({ theme }) => theme.colors.background};
    border-bottom: ${({ theme }) => theme.colors.borderXLight} solid thin;
    z-index: 1;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.textLight};
  }
  thead tr {
    border-bottom: ${({ theme }) => theme.colors.borderXLight} solid thin;
    background-color: ${({ theme }) => theme.colors.background};
  }
  tfoot tr {
    border-top: ${({ theme }) => theme.colors.borderXLight} solid thin;
    font-weight: bold;
  }
  ${media.mobile`
    display: block;
    thead, tbody, tr, th, td { 
      display: block;
      text-align: left !important;
    }
    tr {
      border-bottom: ${({ theme }) => theme.colors.borderXLight} solid thin !important;
    }
  `}
`;

export default Table;

export const SortableTh = styled(Th)<{
  $active?: boolean;
  $ascending?: boolean;
}>`
  cursor: pointer;
  user-select: none;
  color: ${({ theme, $active }) => ($active ? theme.colors.text : theme.colors.textLight)} !important;
  &::before {
    display: inline-block;
    content: "${({ $active }) => (!$active ? '' : '↑')}";
    transition: transform 300ms ease;
    transform: rotate(${({ $ascending }) => ($ascending ? 180 : 0)}deg);
    margin-right: 5px;
  }  
`;
