import React from 'react';
import styled from 'styled-components';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import Button from './Button';
import LoadIcon from './LoadIcon';
import { SuccessIcon } from './SellButton';

const Container = styled.div`
  text-align: center;
`;

export const TxPending: React.FC<{}> = () => (
  <Container>
    <div style={ { marginBottom: 10 } }>
      A confirmation popup will appear. Please confirm it to continue.
    </div>
    <LoadIcon $animating size={ 30 } />
  </Container>
);

export const TxMining: React.FC<{
  txHash: string;
}> = ({
  txHash,
}) => (
  <Container>
    <div style={ { marginBottom: 10 } }>
      Your transaction is being baked to the Tezos blockchain.
      It may take a few seconds to complete.
      You can close this window if you want, it won't affect the transaction.
    </div>
    {
      txHash ? (
        <div style={ { marginBottom: 20 } }>
          Once completed, your transaction info will be accessible through
          {' '}
          <a href={ `https://tzkt.io/${txHash}` } target="_blank" rel="noreferrer" style={ { fontWeight: 'bold' } }>
            this link
          </a>
          .
        </div>
      ) : null
    }
    <LoadIcon $animating size={ 30 } />
  </Container>
);

export const TxSuccess: React.FC<{
  txHash: string;
  handleClick: () => void;
  message?: string;
}> = ({
  txHash,
  handleClick,
  message = 'Your transaction has been completed, congratulations!',
}) => (
  <Container>
    <div style={ { marginBottom: 5 } }><SuccessIcon /></div>
    <div style={ { marginBottom: 10 } }>{ message }</div>
    <div style={ { marginBottom: 20 } }>
      <a href={ `https://tzkt.io/${txHash}` } target="_blank" rel="noreferrer" style={ { fontWeight: 'bold' } }>
        View your transaction
      </a>
    </div>
    <Button onClick={ handleClick }>
      Continue
    </Button>
  </Container>
);

export const TxError: React.FC<{
  txError: string;
  handleClick: () => void;
}> = ({
  txError,
  handleClick,
}) => (
  <Container>
    <div>Something went wrong, please retry.</div>
    <div>{ txError }</div>
    <Button onClick={ handleClick }>
      Close
    </Button>
  </Container>
);

export const TxConnect = () => {
  const { syncTaquito } = useHicetnuncContext();
  return (
    <>
      <div style={ { marginBottom: 20 } }>
        You need to sync your wallet first.
      </div>
      <Button onClick={ syncTaquito } $primary>
        Sync
      </Button>
    </>
  );
};
