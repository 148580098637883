/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import sumBy from 'lodash/sumBy';
import groupBy from 'lodash/groupBy';
import { HiOutlineCheckCircle } from 'react-icons/hi';
import { Objkt } from '~/types';
import Button from './Button';
import Modal, { ModalHeader, ModalCloseButton, ModalBody } from '~/components/Modal';
import TextTruncated from './TextTruncated';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import Input from './Input';
import useCurrentUserHoldings from '~/hooks/useCurrentUserHoldings';
import LoadIcon from './LoadIcon';
import TextLight from './TextLight';
import { formatAmount, xtz } from '~/utils';

const SellButtonContainer = styled(Button)`
  text-align: center;
  justify-content: center;
`;

type NewSwap = {
  quantity: number;
  price: number;
  id: string;
};

const SwapContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  text-align: left;
`;

const TextButton = styled.button`
  all: unset;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const TextError = styled.div`
  color: ${({ theme }) => theme.colors.alert};
`;

export const SuccessIcon = styled(HiOutlineCheckCircle).attrs({ size: 40 })`
  color: ${({ theme }) => theme.colors.success};
`;

const SellForm: React.FC<Objkt & {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  isOpen,
  setIsOpen,
  ...objkt
}) => {
  const holdings = useCurrentUserHoldings(objkt);
  const { swap, acc } = useHicetnuncContext();
  const { address: owner } = acc as { address: string };
  const { minPrice, maxPrice } = objkt;
  const defaultValue = React.useMemo(() => ({
    quantity: 1,
    price: minPrice,
    id: uuid(),
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [minPrice, isOpen]);
  const [swaps, setSwaps] = React.useState<NewSwap[]>([defaultValue]);
  const [status, setStatus] = React.useState<'idle' | 'pending' | 'mining' | 'success' | 'error'>('idle');
  const [transactionHash, setTransactionHash] = React.useState<string>(null);
  const [error, setError] = React.useState<string>();
  const totalQuantity = React.useMemo(() => sumBy(swaps, 'quantity'), [swaps]);
  const handleAddSwap = () => setSwaps((s) => [...s, ...[{ ...defaultValue, id: uuid() }]]);
  const handleRemoveSwap = (id) => setSwaps((s) => s.filter((s_) => s_.id !== id));
  const handleSubmit = async () => {
    try {
      setStatus('pending');
      setTransactionHash(null);
      if (totalQuantity > holdings.quantity) return setError(`You can't sell more than ${holdings.quantity} pieces`);
      const ops = Object.entries(groupBy(swaps, 'price')).map(([price, swaps_]) => ({
        quantity: +sumBy(swaps_, 'quantity'),
        price: +price,
      }));
      const batchOp = await swap({
        owner,
        creator: objkt.creator.address,
        objktId: +objkt.id,
        objktRoyalties: +objkt.royalties,
        ops,
      });
      setStatus('mining');
      setTransactionHash(batchOp.opHash);
      await batchOp.confirmation();
      setStatus('success');
    } catch (err) {
      setStatus('error');
      setError(err.message);
    }
    return null;
  };
  React.useEffect(() => {
    setSwaps([defaultValue]);
    setStatus('idle');
    setError(null);
  }, [isOpen, defaultValue]);
  return status === 'success' ? (
    <div>
      <div style={ { marginBottom: 5 } }><SuccessIcon /></div>
      <div style={ { marginBottom: 10 } }>Your objkt has been listed for sale, congratulations!</div>
      <div style={ { marginBottom: 20 } }>
        <a href={ `https://tzkt.io/${transactionHash}` } target="_blank" rel="noreferrer" style={ { fontWeight: 'bold' } }>
          View your transaction
        </a>
      </div>
      <Button onClick={ () => setIsOpen(false) }>
        Continue
      </Button>
    </div>
  ) : status === 'mining' ? (
    <div>
      <div style={ { marginBottom: 10 } }>Your transaction is being baked to the Tezos blockchain. It may take a few seconds to complete. You can close this window if you want, it won't affect the transaction.</div>
      {
        transactionHash ? (
          <div style={ { marginBottom: 20 } }>
            Once completed, your transaction info will be accessible through
            {' '}
            <a href={ `https://tzkt.io/${transactionHash}` } target="_blank" rel="noreferrer" style={ { fontWeight: 'bold' } }>
              this link
            </a>
            .
          </div>
        ) : null
      }
      <LoadIcon $animating size={ 30 } />
    </div>
  ) : (
    <>
      <SwapContainer>
        {
          holdings.quantity > 1 ? (
            <div style={ { flex: 1, marginRight: 15 } }>
              {`Quantity (max ${holdings.quantity})`}
            </div>
          ) : null
        }
        <div style={ { flex: 1, marginRight: 15 } }>
          Price (
          {xtz}
          )
        </div>
        <div style={ { width: 60 } } />
      </SwapContainer>
      {
        swaps.map(({ id, quantity, price }, index) => (
          <SwapContainer key={ `swap.${id}` }>
            {
              holdings.quantity > 1 ? (
                <Input
                  type="number"
                  placeholder="Quantity"
                  step="1"
                  value={ quantity }
                  min={ 0 }
                  max={ holdings.quantity }
                  onChange={
                    (e) => setSwaps((s) => s.map((s_) => ({
                      ...s_,
                      ...(s_.id === id ? {
                        quantity: +e.target.value,
                      } : {}),
                    })))
                  }
                  style={ { flex: 1, marginRight: 15 } }
                />
              ) : null
            }
            <Input
              type="number"
              placeholder="Price"
              step="0.01"
              value={ price }
              min={ 0 }
              onChange={
                (e) => setSwaps((s) => s.map((s_) => ({
                  ...s_,
                  ...(s_.id === id ? {
                    price: +e.target.value,
                  } : {}),
                })))
              }
              style={ { flex: 1, marginRight: 15 } }
            />
            <div style={ { width: 60 } }>
              {
                index > 0 ? (
                  <TextButton onClick={ () => handleRemoveSwap(id) }>Remove</TextButton>
                ) : null
              }
            </div>
          </SwapContainer>
        ))
      }
      {
        holdings.quantity > totalQuantity ? (
          <div style={ { textAlign: 'left', marginTop: 10 } }>
            <TextButton onClick={ handleAddSwap }>
              Add another listing
            </TextButton>
          </div>
        ) : null
      }
      {
        error ? (
          <TextError style={ { marginTop: 10 } }>
            {`Something went wrong: ${error}`}
          </TextError>
        ) : null
      }
      <TextLight style={
        {
          textAlign: 'left',
          marginTop: 10,
          marginBottom: 10,
          ...(holdings.quantity < totalQuantity ? {
            color: 'red',
          } : {}),
        }
      }
      >
        {
          holdings.quantity === totalQuantity
            ? null
            : holdings.quantity > totalQuantity
              ? `Quantity left to list: ${holdings.quantity - totalQuantity}`
              : 'You are listing more items than you have!'
        }
      </TextLight>
      <TextLight style={
        {
          textAlign: 'left',
          marginTop: 10,
          marginBottom: 10,
        }
      }
      >
        {
          !minPrice && !maxPrice
            ? null
            : !maxPrice || (minPrice === maxPrice)
              ? `This objkt is currently selling for ${formatAmount(minPrice, 2)} ${xtz}.`
              : `This objkt is currently selling between ${formatAmount(minPrice, 2)} and ${formatAmount(maxPrice, 2)} ${xtz}.`
        }
      </TextLight>
      <Button onClick={ handleSubmit } $primary disabled={ status === 'pending' } style={ { marginTop: 15 } }>
        { status === 'pending' ? 'Confirming...' : 'Confirm listing' }
      </Button>
    </>
  );
};

const SellButton: React.FC<Objkt & {
  className?: string;
  style?: {
    [k: string]: string | number;
  };
}> = ({ className, style, ...objkt }) => {
  const { title } = objkt;
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { syncTaquito, acc } = useHicetnuncContext();
  const handleClick = async () => {
    setIsOpen(true);
  };
  return (
    <>
      <SellButtonContainer $primary className={ className } style={ style } onClick={ handleClick }>
        Sell
      </SellButtonContainer>
      <Modal isOpen={ isOpen } handleClose={ () => setIsOpen(false) }>
        <ModalHeader>
          <TextTruncated as="h2" style={ { margin: 0 } }>
            { `Sell ${title}` }
          </TextTruncated>
          <ModalCloseButton onClick={ () => setIsOpen(false) } />
        </ModalHeader>
        <ModalBody style={ { padding: 30, textAlign: 'center' } }>
          {
            acc ? (
              <SellForm { ...objkt } { ...{ isOpen, setIsOpen } } />
            ) : (
              <>
                <div style={ { marginBottom: 20 } }>
                  You need to sync your wallet first.
                </div>
                <Button onClick={ syncTaquito } $primary>
                  Sync
                </Button>
              </>
            )
          }
        </ModalBody>
      </Modal>
    </>
  );
};

export default SellButton;
